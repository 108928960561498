<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="名称">
				<Input placeholder="请输入名称" v-model="searchParams.viewName" />
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				@click="handleAddShow"
				v-if="
					projectActionPermissions.indexOf('btn_project_goods_apparatus_add') >
					-1
				"
			>
				新增器械
			</Button>
			<Button
				@click="handleAddMaterialShow"
				v-if="
					projectActionPermissions.indexOf('btn_project_goods_apparatus_add') >
					-1
				"
			>
				新增耗材
			</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:sponsorList="sponsorList"
			:drugRouteList="drugRouteList"
			:drugTypeList="drugTypeList"
			:id="updateId"
		></Add>
		<ViewDetail
			:visible="viewVisible"
			@onCancel="viewVisible = false"
			:id="viewId"
		></ViewDetail>
		<AddMaterial
			:visible="addMaterialVisible"
			:sponsorList="sponsorList"
			@onCancel="addMaterialVisible = false"
			@onOk="handleAddMaterialOk"
			:type="type"
			:apiGet="apiGet"
			:apiAdd="apiAdd"
			:apiUpdate="apiUpdate"
			:id="updateId"
		></AddMaterial>
		<ViewDetailMaterial
			:visible="viewMaterialVisible"
			@onCancel="viewMaterialVisible = false"
			:type="type"
			:apiGet="apiGet"
			:id="viewId"
		></ViewDetailMaterial>
	</div>
</template>

<script>
import api from "@/api/project/apparatus"
import publicApi from "@/api/public"
import AddMaterial from "@/components/BusinessComponents/Material/Add.vue"
import ViewDetailMaterial from "@/components/BusinessComponents/Material/ViewDetail.vue"
import { mapState } from "vuex"
import ViewDetail from "./ViewDetail.vue"
import Add from "./Add.vue"

const { apiGetPage, apiGet, apiAdd, apiUpdate, apiDelete } = api
const { publicGetOrganizationList, publicGetDictionaryList } = publicApi

export default {
	name: "index",
	components: {
		Add,
		ViewDetail,
		AddMaterial,
		ViewDetailMaterial
	},
	data() {
		return {
			listData: [],
			columns: [
				{
					title: "名称",
					key: "viewName",
					minWidth: 250
				},
				{
					title: "当前库存",
					key: "currAmount",
					minWidth: 150
				},
				{
					title: "总库存",
					key: "totalAmount",
					minWidth: 150
				},
				{
					title: "类型",
					key: "type",
					minWidth: 100,
					render: (h, params) =>
						h("span", {}, ["", "器械", "耗材"][params.row.type])
				},
				{
					title: "创建时间",
					key: "createTime",
					minWidth: 180
				},
				{
					title: "操作",
					key: "action",
					width: 180,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{
								label: "查看",
								on: {
									click: this.handleViewShow
								}
							},
							{
								label: "修改",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "回收",
								on: {
									click: this.handleSwitchShow
								},
								confirm: {
									title: `您确认回收该${params.row.type === 1 ? "器械" : "耗材"}吗？`
								}
							}
						]
						if (params.row.verify === 0) {
							actionList[1] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			loading: false,
			addVisible: false,
			addMaterialVisible: false,
			viewVisible: false,
			viewMaterialVisible: false,
			updateId: "",
			viewId: "",
			searchParams: {
				viewName: ""
			},
			oldSearchParams: {
				viewName: ""
			},
			// 分页
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			sponsorList: [],
			// 给药途径
			drugRouteList: [],
			// 临床用药分类
			drugTypeList: [],
			type: 2
		}
	},
	created() {
		this.getBaseData()
		this.initList()
	},
	computed: {
		...mapState("project", ["infoHeight"]),
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 146 - this.infoHeight
			}
		}),
		...mapState("permission", ["projectActionPermissions"])
	},
	methods: {
		apiGet,
		apiAdd,
		apiUpdate,
		getBaseData() {
			// 获取所有类型为申办方的组织名称
			this.$asyncDo(async () => {
				const res = await publicGetOrganizationList({
					type: 101
				})
				if (res) {
					this.sponsorList = res.data
				}
			})
			// 获取数据字典--给药途径，临床用药分类
			this.$asyncDo(async () => {
				const res = await publicGetDictionaryList()
				if (res) {
					this.drugRouteList = res.data.find(item => item.id === "11").leaf
					this.drugTypeList = res.data.find(item => item.id === "2").leaf
				}
			})
		},
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					projectId: this.$route.params.projectId,
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
		},
		// 新增显示
		handleAddMaterialShow() {
			this.addMaterialVisible = true
			this.updateId = ""
		},
		// 新增药品成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},
		// 新增耗材成功刷新列表
		handleAddMaterialOk() {
			this.addMaterialVisible = false
			this.initList()
		},
		// 查看显示
		handleViewShow({ row }) {
			if (row.type === 1) {
				// 药物
				this.viewVisible = true
			} else {
				// 耗材
				this.viewMaterialVisible = true
			}
			this.viewId = row.id
			this.type = row.type
		},
		// 修改显示
		handleUpdateShow({ row }) {
			if (row.type === 1) {
				// 药物
				this.addVisible = true
			} else {
				// 耗材
				this.addMaterialVisible = true
			}
			this.updateId = row.id
			this.type = row.type
		},
		// 回收
		handleSwitchShow({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiDelete({
					id: row.id,
					type: row.type,
					projectId: this.$route.params.projectId
				})
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				}
				this.loading = false
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				viewName: ""
			}
			this.page.pageSize = 10
			this.searchHandle()
		},
		// 搜索
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		}
	},
	watch: {}
}
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con",style:(`height:${_vm.conHeight}`)},[_c('Form',{ref:"designTemplateForm",class:{ 'design-form-config': true, design: _vm.isShow },staticStyle:{"position":"relative"},attrs:{"label-postion":"top","model":_vm.formData,"rules":_vm.rules}},[(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e(),(_vm.isShowTerm)?_c('Row',{attrs:{"gutter":32}},[(_vm.projectInfo.terminateDate)?_c('Col',{attrs:{"span":8}},[_c('div',{staticClass:"form-item-wrap terminate-item"},[_c('FormItem',{attrs:{"label":"项目终止日期"}},[_vm._v("\n              "+_vm._s(_vm.projectInfo.terminateDate)+"\n            ")])],1)]):_vm._e(),(_vm.projectInfo.terminateRemark)?_c('Col',{attrs:{"span":16}},[_c('div',{staticClass:"form-item-wrap terminate-item"},[_c('FormItem',{attrs:{"label":"项目终止理由"}},[_vm._v("\n              "+_vm._s(_vm.projectInfo.terminateRemark)+"\n            ")])],1)]):_vm._e()],1):_vm._e(),_c('Row',{attrs:{"gutter":32}},[(!_vm.undraggable)?[_c('draggable',{staticClass:"list-item-wrap",attrs:{"disabled":_vm.undraggable},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},_vm._l((_vm.list),function(item,index){return _c('Col',{key:item.id,attrs:{"span":item.json.col || 8}},[_c('div',{staticClass:"form-item-wrap"},[_c('FormItem',{attrs:{"prop":`${item.json.code}`}},[_c('div',{staticClass:"form-item-header",on:{"click":() => _vm.toggleSelect(item)}},[_c('Checkbox',{staticStyle:{"margin-right":"8px"},on:{"on-change":_vm.handleCheckChange},model:{value:(item.json.isSelected),callback:function ($$v) {_vm.$set(item.json, "isSelected", $$v)},expression:"item.json.isSelected"}}),_c('span',{staticClass:"form-item-label"},[_vm._v(_vm._s(_vm.isShowLabel(item) ? item.json.name : ''))])],1),(item.json.type === 'base_textPrompt')?_c('p',{style:({
											textIndent: item.json.textIndent + 'px',
											color: item.json.textColor || '#515a6e'
										})},[_vm._v("\n                    "+_vm._s(_vm.formData[item.json.code])+"\n                  ")]):_vm._e(),(item.json.type === 'base_Input')?_c('Input',{attrs:{"type":"text","maxlength":item.json.maxlength
												? item.json.maxlength.toString()
												: null,"placeholder":item.json.placeholder,"disabled":_vm.isReadonly || item.json.disabled},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData[item.json.code]"}}):_vm._e(),(item.json.type === 'base_Textarea')?_c('Input',{attrs:{"type":"textarea","maxlength":item.json.maxlength
												? item.json.maxlength.toString()
												: null,"placeholder":item.json.placeholder,"disabled":_vm.isReadonly ||
											item.json.disabled ||
											item.json.types[1] === 'AutoGenerate',"rows":item.json.rows},on:{"on-blur":function($event){return _vm.onBlur(_vm.formData[item.json.code], item)}},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData[item.json.code]"}}):_vm._e(),(
											item.alias === 'project_name' &&
											_vm.isRepeat &&
											_vm.formData[item.json.code]
										)?_c('div',{staticClass:"ivu-form-item-error-tip"},[_vm._v("\n                    同名项目已存在\n                  ")]):_vm._e(),(item.json.type === 'base_Select')?[(item.json.isRequest === '2')?_c('Select',{key:`${item.json.code}_${index}_role`,attrs:{"label-in-value":"","placeholder":item.json.placeholder,"filterable":"","clearable":"","disabled":_vm.isReadonly || item.json.disabled,"transfer":true},on:{"on-change":val => _vm.handleChangeSelect(val, item, 'role')},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, $$v)},expression:"formData[item.json.code]"}},_vm._l((item.json.optionList),function(opt,i){return _c('Option',{key:`${opt.value}_${i}`,attrs:{"value":opt.value,"label":opt.label}},[_c('span',[_vm._v(_vm._s(opt.label))]),_c('span',{staticStyle:{"float":"right","color":"#ccc"}},[_vm._v(_vm._s(opt.email))])])}),1):_c('Select',{key:`${item.json.code}_${index}_normal`,attrs:{"placeholder":item.json.placeholder,"filterable":"","clearable":"","disabled":_vm.isReadonly || item.json.disabled,"transfer":true},on:{"on-change":val => _vm.handleChangeSelect(val, item)},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, $$v)},expression:"formData[item.json.code]"}},_vm._l((item.json.optionList),function(opt,i){return _c('Option',{key:`${opt.value}_${i}`,attrs:{"value":opt.label,"label":opt.label}},[_c('span',[_vm._v(_vm._s(opt.label))])])}),1)]:_vm._e(),(item.json.type === 'base_Radio')?_c('RadioGroup',{attrs:{"placeholder":item.json.placeholder},on:{"on-change":value => _vm.itemRadioChange(value, item)},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, $$v)},expression:"formData[item.json.code]"}},_vm._l((item.json.optionList),function(opt,i){return _c('Radio',{key:`${i}_${opt.value}`,style:({
												display: item.json.styles ? 'block' : 'inline-block'
											}),attrs:{"label":opt.value,"disabled":_vm.isReadonly || item.json.disabled}},[_vm._v(_vm._s(opt.label))])}),1):_vm._e(),(
											item.json.type === 'base_Checkbox' ||
											item.json.type === 'base_Checkbox_List'
										)?_c('CheckboxGroup',{attrs:{"placeholder":item.json.placeholder},on:{"on-change":value => _vm.itemCheckboxChange(value, item)},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, $$v)},expression:"formData[item.json.code]"}},_vm._l((item.json.optionList),function(opt,i){return _c('Checkbox',{key:`${i}-${opt.value}`,style:({
												display: item.json.styles ? 'block' : 'inline-block'
											}),attrs:{"label":opt.value,"disabled":_vm.isReadonly || item.json.disabled}},[_vm._v(_vm._s(opt.label))])}),1):_vm._e(),(item.json.type === 'base_Date')?_c('DatePicker',{attrs:{"transfer":"","type":item.json.bindType,"placeholder":item.json.placeholder,"disabled":_vm.isReadonly || item.json.disabled},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, $$v)},expression:"formData[item.json.code]"}}):_vm._e(),(item.json.types[1] === 'Signature')?_c('div',{on:{"click":function($event){return _vm.handleSignatureShow(item)}}},[(_vm.formData[item.json.code])?_c('img',{attrs:{"src":_vm.formData[item.json.code]}}):_c('img',{attrs:{"src":item.json.img}})]):_vm._e(),(item.json.type === 'base_Table')?_c('TableComp',{attrs:{"tableItem":item.json,"value":_vm.formData[item.json.code],"disabled":_vm.isReadonly || item.json.disabled},on:{"update:tableItem":function($event){return _vm.$set(item, "json", $event)},"update:table-item":function($event){return _vm.$set(item, "json", $event)},"update:value":function($event){return _vm.$set(_vm.formData, item.json.code, $event)}}}):_vm._e(),(item.json.type === 'elec_sign')?_c('div',{staticClass:"elec-sign-wrap"},[(item.json.elecSignLoading)?_c('Spin',{attrs:{"fix":""}}):_vm._e(),_c('div',{staticStyle:{"overflow":"hidden","height":"33px"}},[(!item.json.isSelfSign)?_c('Button',{staticStyle:{"float":"left"},attrs:{"type":"primary","loading":item.json.elecSignLoading,"disabled":_vm.isReadonly || item.json.disabled},on:{"click":function($event){return _vm.handleElecSign(item, index)}}},[_vm._v("签名")]):_c('Button',{staticStyle:{"float":"left"},attrs:{"loading":item.json.elecSignLoading,"disabled":_vm.isReadonly || item.json.disabled},on:{"click":function($event){return _vm.handleElecSign(item, index)}}},[_vm._v("撤销签名")])],1),_c('div',{staticClass:"elec-sign-list"},_vm._l((_vm.formData[
													item.json.code
												]),function(signItem,signIndex){return _c('span',{key:`${signItem.userId}_${signIndex}`,staticStyle:{"padding":"0 20px"}},[_c('img',{staticClass:"sign-img",attrs:{"src":`data:image/png;base64,${signItem.img}`}})])}),0)],1):_vm._e()],2)],1)])}),1)]:_vm._l((_vm.list),function(item,index){return _c('Col',{key:item.id,attrs:{"span":item.json.col || 8}},[_c('div',{staticClass:"form-item-wrap"},[_c('FormItem',{attrs:{"prop":`${item.json.code}`}},[_c('div',{staticClass:"form-item-header",on:{"click":() => _vm.toggleSelect(item)}},[_c('Checkbox',{staticStyle:{"margin-right":"8px"},on:{"on-change":_vm.handleCheckChange},model:{value:(item.json.isSelected),callback:function ($$v) {_vm.$set(item.json, "isSelected", $$v)},expression:"item.json.isSelected"}}),_c('span',{staticClass:"form-item-label"},[_vm._v(_vm._s(_vm.isShowLabel(item) ? item.json.name : ''))])],1),(item.json.type === 'base_textPrompt')?_c('p',{style:({
										textIndent: item.json.textIndent + 'px',
										color: item.json.textColor || '#515a6e'
									})},[_vm._v("\n                  "+_vm._s(_vm.formData[item.json.code])+"\n                ")]):_vm._e(),(item.json.type === 'base_Input')?_c('Input',{attrs:{"type":"text","maxlength":item.json.maxlength ? item.json.maxlength.toString() : null,"placeholder":item.json.placeholder,"disabled":_vm.isReadonly || item.json.disabled},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData[item.json.code]"}}):_vm._e(),(item.json.type === 'base_Textarea')?_c('Input',{attrs:{"type":"textarea","maxlength":item.json.maxlength ? item.json.maxlength.toString() : null,"placeholder":item.json.placeholder,"disabled":_vm.isReadonly ||
										item.json.disabled ||
										item.json.types[1] === 'AutoGenerate',"rows":item.json.rows},on:{"on-blur":function($event){return _vm.onBlur(_vm.formData[item.json.code], item)}},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData[item.json.code]"}}):_vm._e(),(
										item.alias === 'project_name' &&
										_vm.isRepeat &&
										_vm.formData[item.json.code]
									)?_c('div',{staticClass:"ivu-form-item-error-tip"},[_vm._v("\n                  同名项目已存在\n                ")]):_vm._e(),(item.json.type === 'base_Select')?[(item.json.isRequest === '2')?_c('Select',{key:`${item.json.code}_${index}_role`,attrs:{"label-in-value":"","placeholder":item.json.placeholder,"filterable":"","clearable":"","disabled":_vm.isReadonly || item.json.disabled,"transfer":true},on:{"on-change":val => _vm.handleChangeSelect(val, item, 'role')},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, $$v)},expression:"formData[item.json.code]"}},_vm._l((item.json.optionList),function(opt,i){return _c('Option',{key:`${opt.value}_${i}`,attrs:{"value":opt.value,"label":opt.label}},[_c('span',[_vm._v(_vm._s(opt.label))]),_c('span',{staticStyle:{"float":"right","color":"#ccc"}},[_vm._v(_vm._s(opt.email))])])}),1):_c('Select',{key:`${item.json.code}_${index}_normal`,attrs:{"placeholder":item.json.placeholder,"filterable":"","clearable":"","disabled":_vm.isReadonly || item.json.disabled,"transfer":true},on:{"on-change":val => _vm.handleChangeSelect(val, item)},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, $$v)},expression:"formData[item.json.code]"}},_vm._l((item.json.optionList),function(opt,i){return _c('Option',{key:`${opt.value}_${i}`,attrs:{"value":opt.label,"label":opt.label}},[_c('span',[_vm._v(_vm._s(opt.label))])])}),1)]:_vm._e(),(item.json.type === 'base_Radio')?_c('RadioGroup',{attrs:{"placeholder":item.json.placeholder},on:{"on-change":value => _vm.itemRadioChange(value, item)},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, $$v)},expression:"formData[item.json.code]"}},_vm._l((item.json.optionList),function(opt,i){return _c('Radio',{key:`${i}_${opt.value}`,style:({
											display: item.json.styles ? 'block' : 'inline-block'
										}),attrs:{"label":opt.value,"disabled":_vm.isReadonly || item.json.disabled}},[_vm._v(_vm._s(opt.label))])}),1):_vm._e(),(
										item.json.type === 'base_Checkbox' ||
										item.json.type === 'base_Checkbox_List'
									)?_c('CheckboxGroup',{attrs:{"placeholder":item.json.placeholder},on:{"on-change":value => _vm.itemCheckboxChange(value, item)},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, $$v)},expression:"formData[item.json.code]"}},_vm._l((item.json.optionList),function(opt,i){return _c('Checkbox',{key:`${i}-${opt.value}`,style:({
											display: item.json.styles ? 'block' : 'inline-block'
										}),attrs:{"label":opt.value,"disabled":_vm.isReadonly || item.json.disabled}},[_vm._v(_vm._s(opt.label))])}),1):_vm._e(),(item.json.type === 'base_Date')?_c('DatePicker',{attrs:{"transfer":"","type":item.json.bindType,"placeholder":item.json.placeholder,"disabled":_vm.isReadonly || item.json.disabled},model:{value:(_vm.formData[item.json.code]),callback:function ($$v) {_vm.$set(_vm.formData, item.json.code, $$v)},expression:"formData[item.json.code]"}}):_vm._e(),(item.json.types[1] === 'Signature')?_c('div',{on:{"click":function($event){return _vm.handleSignatureShow(item)}}},[(_vm.formData[item.json.code])?_c('img',{attrs:{"src":_vm.formData[item.json.code]}}):_c('img',{attrs:{"src":item.json.img}})]):_vm._e(),(item.json.type === 'base_Table')?_c('TableComp',{attrs:{"tableItem":item.json,"value":_vm.formData[item.json.code],"disabled":_vm.isReadonly || item.json.disabled},on:{"update:tableItem":function($event){return _vm.$set(item, "json", $event)},"update:table-item":function($event){return _vm.$set(item, "json", $event)},"update:value":function($event){return _vm.$set(_vm.formData, item.json.code, $event)}}}):_vm._e(),(
										item.json.type === 'elec_sign' &&
										(_vm.isReadonly ||
											item.json.disabled ||
											item.json.signType !== 1)
									)?_c('div',{staticClass:"elec-sign-wrap"},[(item.json.elecSignLoading)?_c('Spin',{attrs:{"fix":""}}):_vm._e(),_c('div',{staticStyle:{"overflow":"hidden","height":"33px"}},[(!item.json.isSelfSign)?_c('Button',{staticStyle:{"float":"left"},attrs:{"type":"primary","loading":item.json.elecSignLoading,"disabled":_vm.isReadonly || item.json.disabled},on:{"click":function($event){return _vm.handleElecSign(item, index)}}},[_vm._v("签名")]):_c('Button',{staticStyle:{"float":"left"},attrs:{"loading":item.json.elecSignLoading,"disabled":_vm.isReadonly || item.json.disabled},on:{"click":function($event){return _vm.handleElecSign(item, index)}}},[_vm._v("撤销签名")])],1),_c('div',{staticClass:"elec-sign-list"},_vm._l((_vm.formData[item.json.code]),function(signItem,signIndex){return _c('span',{key:`${signItem.userId}_${signIndex}`,staticClass:"elec-sign-item"},[_c('img',{staticClass:"sign-img",attrs:{"src":`data:image/png;base64,${signItem.img}`}}),(
													_vm.env === 'shenzhenrenmin' && _vm.showType === 'history'
												)?_c('span',[_vm._v(_vm._s(_vm.getTime(signItem.time)))]):_vm._e()])}),0)],1):_vm._e()],2)],1)])})],2)],1),(_vm.showType === 'approval')?_c('Modal',{attrs:{"title":"输入签名密码","value":_vm.signatureVisible,"mask-closable":false},on:{"on-cancel":_vm.signatureCancel}},[(_vm.signatureVisible)?_c('Form',{ref:"signature"},[_c('p',[_c('a',{staticClass:"sign-link",on:{"click":_vm.settingSign}},[_vm._v("签名设置点这里")])]),_c('FormItem',{attrs:{"label":"签名密码"}},[_c('Input',{attrs:{"placeholder":"请输入签名密码","type":"password"},on:{"on-keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleGetSignatureSubmit.apply(null, arguments)}},model:{value:(_vm.signaturePassword),callback:function ($$v) {_vm.signaturePassword=$$v},expression:"signaturePassword"}})],1)],1):_vm._e(),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{on:{"click":_vm.signatureCancel}},[_vm._v("取消")]),_c('Button',{attrs:{"type":"primary","loading":_vm.signatureLoading},on:{"click":_vm.handleGetSignatureSubmit}},[_vm._v("确定")])],1)],1):_vm._e(),_c('SignatureModal',{attrs:{"visible":_vm.settingSignVisible},on:{"onCancel":function($event){_vm.settingSignVisible = false}}}),_c('Modal',{attrs:{"title":"提示","mask-closable":false,"loading":_vm.elecSignModalLoading},on:{"on-cancel":_vm.handleElecSignCancel},model:{value:(_vm.elecSignVisible),callback:function ($$v) {_vm.elecSignVisible=$$v},expression:"elecSignVisible"}},[_c('p',{staticStyle:{"padding":"20px","font-size":"16px"}},[_vm._v("\n        请打开"+_vm._s(_vm.appName)+"APP验证证书密码！\n      ")]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{on:{"click":_vm.handleElecSignCancel}},[_vm._v("取消")]),_c('Button',{attrs:{"type":"primary","loading":_vm.elecSignModalLoading},on:{"click":_vm.handleElecSignSubmit}},[_vm._v("已验证")])],1)])],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import request from "@/utils/request"

export default {
	// 获取列表
	apiGetPage: data =>
		request(`/project/${data.projectId}/apparatus`, {
			params: data
		}),
	// 新增
	apiAdd: data =>
		request(`/project/${data.projectId}/apparatus`, {
			method: "post",
			body: data
		}),
	// 获取详情
	apiGet: data =>
		request(`/project/${data.projectId}/apparatus/${data.id}`, {
			params: data
		}),
	// 修改
	apiUpdate: data =>
		request(`/project/${data.projectId}/apparatus`, {
			method: "put",
			body: data
		}),
	// 回收（禁用）
	apiDelete: data =>
		request(`/project/${data.projectId}/apparatus/${data.id}`, {
			method: "post",
			body: data
		})
}

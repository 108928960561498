<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="耗材编号" prop="suppliesNo">
						<Input v-model="formData.suppliesNo" placeholder="请输入耗材编号" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="耗材名称" prop="suppliesName">
						<Input
							v-model="formData.suppliesName"
							placeholder="请输入耗材名称"
							:disabled="!!id"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="数量" prop="totalAmount">
						<Input v-model="formData.totalAmount" placeholder="请输入数量" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="制造厂商" prop="organizationName">
						<Input
							v-model="formData.organizationName"
							placeholder="请输入制造厂商"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="使用方式" prop="useDescription">
						<Input
							type="textarea"
							v-model="formData.useDescription"
							placeholder="请输入使用方式"
						/>
					</FormItem>
				</Col>
			</Row>
			<ExtraForm
				@extraValueChange="(key, value) => handleExtraValueChange(key, value)"
				:list="extraList"
				:data="formData"
			></ExtraForm>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import ExtraForm from "@/components/BusinessComponents/ExtendForm/ExtraForm.vue"
import ExtraMixin from "@/mixins/ExtraMixin"

export default {
	name: "Add",
	props: [
		"visible",
		"id",
		"apiGet",
		"apiAdd",
		"apiUpdate",
		"sponsorList",
		"type"
	],
	mixins: [ExtraMixin],
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rules: {
				suppliesNo: {
					required: true,
					message: "耗材编号不能为空",
					trigger: "blur"
				},
				suppliesName: {
					required: true,
					message: "耗材名称不能为空",
					trigger: "blur"
				},
				useDescription: {
					required: true,
					message: "使用方式不能为空",
					trigger: "blur"
				},
				organizationName: {
					required: true,
					message: "制造厂商不能为空",
					trigger: "blur"
				},
				totalAmount: [
					{ required: true, message: "数量不能为空", trigger: "blur" },
					{
						...this.rules.positiveInteger,
						message: "数量必须为正整数",
						trigger: "blur"
					}
				]
			},
			extraList: []
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {}
				this.title = "新增耗材"

				if (this.id) {
					this.title = "修改耗材"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await this.apiGet({
							id: this.id,
							type: 2,
							projectId: this.$route.params.projectId
						})
						if (res) {
							this.formData = {
								...this.formData,
								...res.data,
								...JSON.parse(res.data.extraData)
							}
							this.formData.totalAmount = String(this.formData.totalAmount)
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.id) {
							res = await this.apiUpdate({
								jsonData: {
									...this.formData,
									id: this.id,
									projectId: this.$route.params.projectId,
									extraData: this.getExtraData()
								},
								projectId: this.$route.params.projectId,
								type: 2
							})
						} else {
							res = await this.apiAdd({
								jsonData: {
									...this.formData,
									projectId: this.$route.params.projectId,
									extraData: this.getExtraData()
								},
								projectId: this.$route.params.projectId,
								type: 2
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	},
	components: {
		ExtraForm
	},
	created() {
		if (this.type === 1) {
			this.getExtraList("DRUG_SUPPLY")
		} else {
			this.getExtraList("APPARATUS_SUPPLY")
		}
	}
}
</script>

<style scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrap"},[(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e(),_c('CtmsSearchBox',{on:{"onSearch":_vm.searchHandle,"onReset":_vm.reset}},[_c('CtmsSearchBoxItem',{attrs:{"span":1,"label":"名称"}},[_c('Input',{attrs:{"placeholder":"请输入名称"},model:{value:(_vm.searchParams.viewName),callback:function ($$v) {_vm.$set(_vm.searchParams, "viewName", $$v)},expression:"searchParams.viewName"}})],1)],1),_c('CtmsAction',[(
				_vm.projectActionPermissions.indexOf('btn_project_goods_apparatus_add') >
				-1
			)?_c('Button',{on:{"click":_vm.handleAddShow}},[_vm._v("\n\t\t\t新增器械\n\t\t")]):_vm._e(),(
				_vm.projectActionPermissions.indexOf('btn_project_goods_apparatus_add') >
				-1
			)?_c('Button',{on:{"click":_vm.handleAddMaterialShow}},[_vm._v("\n\t\t\t新增耗材\n\t\t")]):_vm._e()],1),_c('CtmsDataGrid',{attrs:{"height":_vm.dataGridHeight,"columns":_vm.columns,"data":_vm.listData,"page":_vm.page}}),_c('Add',{attrs:{"visible":_vm.addVisible,"sponsorList":_vm.sponsorList,"drugRouteList":_vm.drugRouteList,"drugTypeList":_vm.drugTypeList,"id":_vm.updateId},on:{"onCancel":function($event){_vm.addVisible = false},"onOk":_vm.handleAddOk}}),_c('ViewDetail',{attrs:{"visible":_vm.viewVisible,"id":_vm.viewId},on:{"onCancel":function($event){_vm.viewVisible = false}}}),_c('AddMaterial',{attrs:{"visible":_vm.addMaterialVisible,"sponsorList":_vm.sponsorList,"type":_vm.type,"apiGet":_vm.apiGet,"apiAdd":_vm.apiAdd,"apiUpdate":_vm.apiUpdate,"id":_vm.updateId},on:{"onCancel":function($event){_vm.addMaterialVisible = false},"onOk":_vm.handleAddMaterialOk}}),_c('ViewDetailMaterial',{attrs:{"visible":_vm.viewMaterialVisible,"type":_vm.type,"apiGet":_vm.apiGet,"id":_vm.viewId},on:{"onCancel":function($event){_vm.viewMaterialVisible = false}}})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="器械编号" prop="apparatusNO">
						<Input
							v-model="formData.apparatusNO"
							placeholder="请输入器械编号"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="器械名称" prop="apparatusName">
						<Input
							v-model="formData.apparatusName"
							placeholder="请输入器械名称"
							:disabled="!!id"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="器械数量" prop="totalAmount">
						<Input
							v-model="formData.totalAmount"
							placeholder="请输入器械数量"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="制造厂商" prop="organizationName">
						<Input
							v-model="formData.organizationName"
							placeholder="请输入制造厂商"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="使用方式" prop="useDescription">
						<Input
							v-model="formData.useDescription"
							type="textarea"
							placeholder="请输入使用方式"
						/>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="备注" prop="mark">
						<Input
							v-model="formData.mark"
							type="textarea"
							placeholder="请输入备注"
						/>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/apparatus"

const { apiGet, apiAdd, apiUpdate } = api

export default {
	name: "Add",
	props: ["visible", "id", "sponsorList"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rules: {
				apparatusNO: {
					required: true,
					message: "器械编号不能为空",
					trigger: "blur"
				},
				apparatusName: {
					required: true,
					message: "器械名称不能为空",
					trigger: "blur"
				},
				useDescription: {
					required: true,
					message: "使用方式不能为空",
					trigger: "blur"
				},
				organizationName: {
					required: true,
					message: "制造厂商不能为空",
					trigger: "blur"
				},
				totalAmount: [
					{ required: true, message: "器械数量不能为空", trigger: "blur" },
					{
						...this.rules.positiveInteger,
						message: "器械数量必须为正整数",
						trigger: "blur"
					}
				]
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {}
				this.title = "新增器械"
				if (this.id) {
					this.title = "修改器械"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({
							projectId: this.$route.params.projectId,
							id: this.id,
							type: 1
						})
						if (res) {
							this.formData = {
								...this.formData,
								...res.data
							}
							this.formData.totalAmount = String(this.formData.totalAmount)
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.id) {
							res = await apiUpdate({
								projectId: this.$route.params.projectId,
								jsonData: {
									...this.formData,
									id: this.id,
									projectId: this.$route.params.projectId
								},
								type: 1
							})
						} else {
							res = await apiAdd({
								projectId: this.$route.params.projectId,
								jsonData: {
									...this.formData,
									projectId: this.$route.params.projectId
								},
								type: 1
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style scoped></style>

<template>
	<div class="project-form-setting-wrapper">
		<Spin fix v-if="loading"></Spin>
		<div class="ctms-form-design">
			<div class="form-component-group">
				<h2>
					项目信息管理配置
					<Button
						style="margin-left: 30px; padding: 4px 35px"
						type="primary"
						@click="onOk"
						:loading="loading"
						>保存配置
					</Button>
					<Button style="margin-left: 30px; padding: 4px 35px" @click="goback"
						>返回列表
					</Button>
					<Checkbox
						v-model="selectAll"
						style="margin-left: 30px; padding: 4px 15px"
						@on-change="handleSelectAll"
					>全选</Checkbox>
				</h2>
				<designTemplate
					ref="designTemplate"
					:componentList.sync="componentList"
					:submitIdList="submitIdList"
					showType="design"
					@onDeleItem="handleDeleChosen"
					@on-select-change="handleSelectChange"
				></designTemplate>
			</div>
		</div>
	</div>
</template>

<script>
import designTemplate from "@/components/BusinessComponents/FormDesign/designTemplateConfig.vue"
import api from "@/api/project/project"
import formItemApi from "@/api/setting/formItem"
import { mapState } from "vuex"

const { apiGetFormSetting, apiSetFormSettingConfig } = api
const { getFormItemList } = formItemApi
export default {
	name: "projectFormManageConfig",
	components: {
		designTemplate
	},
	data() {
		return {
			loading: false,
			formItemList: [],
			allFormItemList: [],
			componentList: [],
			submitIdList: [],
			selectAll: false
		}
	},
	computed: {
		...mapState({
			contentHeight: state => state.contentHeight - 90
		})
	},
	created() {
		this.getList()
	},
	watch: {
		componentList: {
			handler(newList) {
				if (newList && newList.length) {
					this.checkSelectAll()
				} else {
					this.selectAll = false
				}
			},
			deep: true
		}
	},
	methods: {
		async getList() {
			this.loading = true
			const res1 = await apiGetFormSetting(this.$route.query.id)
			if (res1) {
				const { json } = res1.data
				this.submitIdList = res1.data.submitIdList || []
				if (json) {
					this.componentList = JSON.parse(json)
					
					if (!this.submitIdList.length && this.componentList.length) {
						this.$nextTick(() => {
							this.handleSelectAll(true)
						})
					}
				}
			}
			// del: 1，查禁用，0查启用，不传，查所有的。
			// isProject 1,只显示已选择是否项目信息 是，并配置过全局唯一编码的表单项。
			const res2 = await getFormItemList({ del: 0, isProject: 1 })
			if (res2) {
				const { data } = res2
				const formItemList = data
				this.allFormItemList = data
				this.formItemList = formItemList.filter(item => {
					const flag = this.componentList.every(comp => comp.id !== item.id)
					return flag
				})
			}
			this.loading = false
		},
		handlenoChosenItemClick(formItem) {
			// 左侧添加到右侧
			this.$refs.designTemplate.onAddFormItem(formItem)
			const formList = this.formItemList.filter(item => item.id !== formItem.id)
			this.formItemList = [...formList]
		},
		handleDeleChosen(formItem) {
			//  右侧删除组件到左侧
			const newItem = this.allFormItemList.find(item => item.id === formItem.id)
			this.formItemList.push(newItem)
		},
		async onOk() {
			// 获取所有选中的组件
			const selectedItems = this.$refs.designTemplate.selectedItems;

			if (!selectedItems || selectedItems.length === 0) {
				this.$Message.error("请至少选择一个组件，表单不能为空")
				return
			}

			this.loading = true;
			try {
				const data = selectedItems.map(item => {
					const obj = {}
					Object.keys(item).forEach(key => {
						if (key === "json") {
							return
						}
						obj[key] = item[key]
					})
					return obj
				})

				const res = await apiSetFormSettingConfig({
					componentIds: data.map(item => item.id),
					json: data,
					projectConfigId: this.$route.query.id
				})

				if (res) {
					this.$Message.success("操作成功！")
					this.$router.push({
						name: "settingProjectFormList"
					})
				}
			} catch (error) {
				this.$Message.error("保存失败！")
			} finally {
				this.loading = false
			}
		},
		goback() {
			this.$router.push({
				name: "settingProjectFormList"
			})
		},
		handleSelectAll(checked) {
			if (this.$refs.designTemplate) {
				this.$refs.designTemplate.handleSelectAll(checked)
				this.selectAll = checked
			}
		},
		handleSelectChange(selectedItems, allItems) {
			this.selectAll = selectedItems.length === allItems.length && allItems.length > 0
		},
		checkSelectAll() {
			if (this.$refs.designTemplate) {
				const { selectedItems, list } = this.$refs.designTemplate
				this.selectAll = selectedItems.length === list.length && list.length > 0
			}
		}
	}
}
</script>

<style scoped lang="less">
.project-form-setting-wrapper {
	height: 100%;
	background-color: #fff;
	padding: 16px;
	.ctms-form-design {
		display: flex;
		/*height: ~'calc(100% - 48px)';*/
		.form-component-group {
			padding-right: 10px;
			.component-list {
				padding: 15px;
				/*overflow-y: auto;*/
			}
			h2 {
				font-size: 16px;
				padding: 0 10px;
				display: flex;
				align-items: center;
				
				.ivu-checkbox-wrapper {
					font-size: 14px;
					margin-top: 2px;
				}
			}
			&:first-child {
				border-right: 1px solid #ddd;
				width: 390px;
			}
			&:last-child {
				flex: 1;
				overflow: auto;
				h2 {
					color: #f60;
					font-size: 20px;
					margin-bottom: 15px;
				}
			}
			ul {
				list-style: none;
				li {
					margin-top: 16px;
				}
			}
		}
	}
	.footer-wrapper {
		text-align: right;
		padding-right: 20px;
		margin-top: 16px;
	}
}
</style>

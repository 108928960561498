<template>
  <!--id="designTemplateForm"-->
  <div class="con" :style="`height:${conHeight}`">
    <Form
      label-postion="top"
      ref="designTemplateForm"
      :model="formData"
      :rules="rules"
      style="position: relative"
      :class="{ 'design-form-config': true, design: isShow }"
    >
      <Spin fix v-if="loading"></Spin>
      <Row :gutter="32" v-if="isShowTerm">
        <Col :span="8" v-if="projectInfo.terminateDate">
          <div class="form-item-wrap terminate-item">
            <FormItem label="项目终止日期">
              {{ projectInfo.terminateDate }}
            </FormItem>
          </div>
        </Col>
        <Col :span="16" v-if="projectInfo.terminateRemark">
          <div class="form-item-wrap terminate-item">
            <FormItem label="项目终止理由">
              {{ projectInfo.terminateRemark }}
            </FormItem>
          </div>
        </Col>
      </Row>
      <Row :gutter="32">
        <!--拖拽-->
        <template v-if="!undraggable">
          <draggable
            v-model="list"
            class="list-item-wrap"
            :disabled="undraggable"
          >
            <Col
              v-for="(item, index) in list"
              :span="item.json.col || 8"
              :key="item.id"
            >
              <div class="form-item-wrap">
                <FormItem
                  :prop="`${item.json.code}`"
                >
                  <div class="form-item-header" @click="() => toggleSelect(item)">
                    <Checkbox
                      v-model="item.json.isSelected"
                      style="margin-right: 8px;"
                      @on-change="handleCheckChange"
                    ></Checkbox>
                    <span class="form-item-label">{{ isShowLabel(item) ? item.json.name : '' }}</span>
                  </div>
                  <!--纯文字提示信息-->
                  <p
                    v-if="item.json.type === 'base_textPrompt'"
                    :style="{
											textIndent: item.json.textIndent + 'px',
											color: item.json.textColor || '#515a6e'
										}"
                  >
                    {{ formData[item.json.code] }}
                  </p>
                  <Input
                    v-if="item.json.type === 'base_Input'"
                    type="text"
                    :maxlength="
											item.json.maxlength
												? item.json.maxlength.toString()
												: null
										"
                    v-model.trim="formData[item.json.code]"
                    :placeholder="item.json.placeholder"
                    :disabled="isReadonly || item.json.disabled"
                  />
                  <Input
                    v-if="item.json.type === 'base_Textarea'"
                    v-model.trim="formData[item.json.code]"
                    type="textarea"
                    :maxlength="
											item.json.maxlength
												? item.json.maxlength.toString()
												: null
										"
                    :placeholder="item.json.placeholder"
                    :disabled="
											isReadonly ||
											item.json.disabled ||
											item.json.types[1] === 'AutoGenerate'
										"
                    :rows="item.json.rows"
                    @on-blur="onBlur(formData[item.json.code], item)"
                  ></Input>
                  <div
                    v-if="
											item.alias === 'project_name' &&
											isRepeat &&
											formData[item.json.code]
										"
                    class="ivu-form-item-error-tip"
                  >
                    同名项目已存在
                  </div>
                  <template v-if="item.json.type === 'base_Select'">
                    <!--isRequest = 2, 是角色接口，人员可能有重名现象，加一下email 区分-->
                    <Select
                      :key="`${item.json.code}_${index}_role`"
                      v-if="item.json.isRequest === '2'"
                      v-model="formData[item.json.code]"
                      label-in-value
                      @on-change="val => handleChangeSelect(val, item, 'role')"
                      :placeholder="item.json.placeholder"
                      filterable
                      clearable
                      :disabled="isReadonly || item.json.disabled"
                      :transfer="true"
                    >
                      <!--:filterable="!item.json.isFilter"-->
                      <Option
                        v-for="(opt, i) in item.json.optionList"
                        :key="`${opt.value}_${i}`"
                        :value="opt.value"
                        :label="opt.label"
                      ><span>{{ opt.label }}</span>
                        <!--如果是角色接口，人员可能有重名现象，加一下email 区分-->
                        <span style="float: right; color: #ccc">{{
                            opt.email
                          }}</span>
                      </Option>
                    </Select>
                    <Select
                      :key="`${item.json.code}_${index}_normal`"
                      v-else
                      v-model="formData[item.json.code]"
                      @on-change="val => handleChangeSelect(val, item)"
                      :placeholder="item.json.placeholder"
                      filterable
                      clearable
                      :disabled="isReadonly || item.json.disabled"
                      :transfer="true"
                    >
                      <!--:filterable="!item.json.isFilter"-->
                      <Option
                        v-for="(opt, i) in item.json.optionList"
                        :key="`${opt.value}_${i}`"
                        :value="opt.label"
                        :label="opt.label"
                      ><span>{{ opt.label }}</span>
                      </Option>
                    </Select>
                  </template>

                  <RadioGroup
                    v-if="item.json.type === 'base_Radio'"
                    v-model="formData[item.json.code]"
                    :placeholder="item.json.placeholder"
                    @on-change="value => itemRadioChange(value, item)"
                  >
                    <Radio
                      :style="{
												display: item.json.styles ? 'block' : 'inline-block'
											}"
                      v-for="(opt, i) in item.json.optionList"
                      :key="`${i}_${opt.value}`"
                      :label="opt.value"
                      :disabled="isReadonly || item.json.disabled"
                    >{{ opt.label }}</Radio
                    >
                    <!--<div
                        v-if="otherShowObj[item.json.code]"
                        style="font-size: 13px;display: inline-block;margin-left: 10px;">
                        <Input
                          type="text"
                          size="small"
                          placeholder="请输入其他选项"
                          style="width: 150px;"></Input>
                      </div>-->
                  </RadioGroup>
                  <CheckboxGroup
                    v-if="
											item.json.type === 'base_Checkbox' ||
											item.json.type === 'base_Checkbox_List'
										"
                    v-model="formData[item.json.code]"
                    :placeholder="item.json.placeholder"
                    @on-change="value => itemCheckboxChange(value, item)"
                  >
                    <Checkbox
                      :style="{
												display: item.json.styles ? 'block' : 'inline-block'
											}"
                      v-for="(opt, i) in item.json.optionList"
                      :key="`${i}-${opt.value}`"
                      :label="opt.value"
                      :disabled="isReadonly || item.json.disabled"
                    >{{ opt.label }}</Checkbox
                    >
                    <!-- <div
                        v-if="otherShowObj[item.json.code]"
                        style="font-size: 13px;display: inline-block;margin-left: 10px;">
                        <Input
                          type="text"
                          size="small"
                          placeholder="请输入其他选项"
                          style="width: 150px;"></Input>
                      </div>-->
                  </CheckboxGroup>
                  <DatePicker
                    transfer
                    v-if="item.json.type === 'base_Date'"
                    v-model="formData[item.json.code]"
                    :type="item.json.bindType"
                    :placeholder="item.json.placeholder"
                    :disabled="isReadonly || item.json.disabled"
                  ></DatePicker>
                  <!--个人签名-->
                  <div
                    v-if="item.json.types[1] === 'Signature'"
                    @click="handleSignatureShow(item)"
                  >
                    <img
                      v-if="formData[item.json.code]"
                      :src="formData[item.json.code]"
                    />
                    <img v-else :src="item.json.img" />
                  </div>
                  <TableComp
                    v-if="item.json.type === 'base_Table'"
                    :tableItem.sync="item.json"
                    :value.sync="formData[item.json.code]"
                    :disabled="isReadonly || item.json.disabled"
                  >
                  </TableComp>
                  <!--电子签名 设计页面-->
                  <div
                    v-if="item.json.type === 'elec_sign'"
                    class="elec-sign-wrap"
                  >
                    <Spin fix v-if="item.json.elecSignLoading"></Spin>
                    <div style="overflow: hidden; height: 33px">
                      <Button
                        type="primary"
                        style="float: left"
                        v-if="!item.json.isSelfSign"
                        :loading="item.json.elecSignLoading"
                        :disabled="isReadonly || item.json.disabled"
                        @click="handleElecSign(item, index)"
                      >签名</Button
                      >
                      <Button
                        v-else
                        style="float: left"
                        :loading="item.json.elecSignLoading"
                        :disabled="isReadonly || item.json.disabled"
                        @click="handleElecSign(item, index)"
                      >撤销签名</Button
                      >
                    </div>
                    <div class="elec-sign-list">
											<span
                        v-for="(signItem, signIndex) in formData[
													item.json.code
												]"
                        style="padding: 0 20px"
                        :key="`${signItem.userId}_${signIndex}`"
                      >
												<img
                          :src="`data:image/png;base64,${signItem.img}`"
                          class="sign-img"
                        />
											</span>
                    </div>
                  </div>
                </FormItem>
              </div>
            </Col>
          </draggable>
        </template>
        <!--禁止拖拽-->
        <template v-else>
          <Col
            v-for="(item, index) in list"
            :span="item.json.col || 8"
            :key="item.id"
          >
            <div class="form-item-wrap">
              <FormItem
                :prop="`${item.json.code}`"
              >
                <div class="form-item-header" @click="() => toggleSelect(item)">
                  <Checkbox
                    v-model="item.json.isSelected"
                    style="margin-right: 8px;"
                    @on-change="handleCheckChange"
                  ></Checkbox>
                  <span class="form-item-label">{{ isShowLabel(item) ? item.json.name : '' }}</span>
                </div>
                <!--纯文字提示信息-->
                <p
                  v-if="item.json.type === 'base_textPrompt'"
                  :style="{
										textIndent: item.json.textIndent + 'px',
										color: item.json.textColor || '#515a6e'
									}"
                >
                  {{ formData[item.json.code] }}
                </p>
                <Input
                  v-if="item.json.type === 'base_Input'"
                  type="text"
                  :maxlength="
										item.json.maxlength ? item.json.maxlength.toString() : null
									"
                  v-model.trim="formData[item.json.code]"
                  :placeholder="item.json.placeholder"
                  :disabled="isReadonly || item.json.disabled"
                />
                <Input
                  v-if="item.json.type === 'base_Textarea'"
                  v-model.trim="formData[item.json.code]"
                  type="textarea"
                  :maxlength="
										item.json.maxlength ? item.json.maxlength.toString() : null
									"
                  :placeholder="item.json.placeholder"
                  :disabled="
										isReadonly ||
										item.json.disabled ||
										item.json.types[1] === 'AutoGenerate'
									"
                  :rows="item.json.rows"
                  @on-blur="onBlur(formData[item.json.code], item)"
                ></Input>
                <div
                  v-if="
										item.alias === 'project_name' &&
										isRepeat &&
										formData[item.json.code]
									"
                  class="ivu-form-item-error-tip"
                >
                  同名项目已存在
                </div>
                <template v-if="item.json.type === 'base_Select'">
                  <!--isRequest = 2, 是角色接口，人员可能有重名现象，加一下email 区分-->
                  <Select
                    :key="`${item.json.code}_${index}_role`"
                    v-if="item.json.isRequest === '2'"
                    v-model="formData[item.json.code]"
                    label-in-value
                    @on-change="val => handleChangeSelect(val, item, 'role')"
                    :placeholder="item.json.placeholder"
                    filterable
                    clearable
                    :disabled="isReadonly || item.json.disabled"
                    :transfer="true"
                  >
                    <!--:filterable="!item.json.isFilter"-->
                    <Option
                      v-for="(opt, i) in item.json.optionList"
                      :key="`${opt.value}_${i}`"
                      :value="opt.value"
                      :label="opt.label"
                    ><span>{{ opt.label }}</span>
                      <!--如果是角色接口，人员可能有重名现象，加一下email 区分-->
                      <span style="float: right; color: #ccc">{{
                          opt.email
                        }}</span>
                    </Option>
                  </Select>
                  <Select
                    :key="`${item.json.code}_${index}_normal`"
                    v-else
                    v-model="formData[item.json.code]"
                    @on-change="val => handleChangeSelect(val, item)"
                    :placeholder="item.json.placeholder"
                    filterable
                    clearable
                    :disabled="isReadonly || item.json.disabled"
                    :transfer="true"
                  >
                    <!--:filterable="!item.json.isFilter"-->
                    <Option
                      v-for="(opt, i) in item.json.optionList"
                      :key="`${opt.value}_${i}`"
                      :value="opt.label"
                      :label="opt.label"
                    ><span>{{ opt.label }}</span>
                    </Option>
                  </Select>
                </template>

                <RadioGroup
                  v-if="item.json.type === 'base_Radio'"
                  v-model="formData[item.json.code]"
                  :placeholder="item.json.placeholder"
                  @on-change="value => itemRadioChange(value, item)"
                >
                  <Radio
                    :style="{
											display: item.json.styles ? 'block' : 'inline-block'
										}"
                    v-for="(opt, i) in item.json.optionList"
                    :key="`${i}_${opt.value}`"
                    :label="opt.value"
                    :disabled="isReadonly || item.json.disabled"
                  >{{ opt.label }}</Radio
                  >
                  <!--<div
                      v-if="otherShowObj[item.json.code]"
                      style="font-size: 13px;display: inline-block;margin-left: 10px;">
                      <Input
                        type="text"
                        size="small"
                        placeholder="请输入其他选项"
                        style="width: 150px;"></Input>
                    </div>-->
                </RadioGroup>
                <CheckboxGroup
                  v-if="
										item.json.type === 'base_Checkbox' ||
										item.json.type === 'base_Checkbox_List'
									"
                  v-model="formData[item.json.code]"
                  :placeholder="item.json.placeholder"
                  @on-change="value => itemCheckboxChange(value, item)"
                >
                  <Checkbox
                    :style="{
											display: item.json.styles ? 'block' : 'inline-block'
										}"
                    v-for="(opt, i) in item.json.optionList"
                    :key="`${i}-${opt.value}`"
                    :label="opt.value"
                    :disabled="isReadonly || item.json.disabled"
                  >{{ opt.label }}</Checkbox
                  >
                  <!-- <div
                      v-if="otherShowObj[item.json.code]"
                      style="font-size: 13px;display: inline-block;margin-left: 10px;">
                      <Input
                        type="text"
                        size="small"
                        placeholder="请输入其他选项"
                        style="width: 150px;"></Input>
                    </div>-->
                </CheckboxGroup>
                <DatePicker
                  transfer
                  v-if="item.json.type === 'base_Date'"
                  v-model="formData[item.json.code]"
                  :type="item.json.bindType"
                  :placeholder="item.json.placeholder"
                  :disabled="isReadonly || item.json.disabled"
                ></DatePicker>
                <!--个人签名-->
                <div
                  v-if="item.json.types[1] === 'Signature'"
                  @click="handleSignatureShow(item)"
                >
                  <img
                    v-if="formData[item.json.code]"
                    :src="formData[item.json.code]"
                  />
                  <img v-else :src="item.json.img" />
                </div>
                <TableComp
                  v-if="item.json.type === 'base_Table'"
                  :tableItem.sync="item.json"
                  :value.sync="formData[item.json.code]"
                  :disabled="isReadonly || item.json.disabled"
                >
                </TableComp>
                <!--CA 电子签名-->
                <div
                  v-if="
										item.json.type === 'elec_sign' &&
										(isReadonly ||
											item.json.disabled ||
											item.json.signType !== 1)
									"
                  class="elec-sign-wrap"
                >
                  <Spin fix v-if="item.json.elecSignLoading"></Spin>
                  <div style="overflow: hidden; height: 33px">
                    <Button
                      type="primary"
                      style="float: left"
                      v-if="!item.json.isSelfSign"
                      :loading="item.json.elecSignLoading"
                      :disabled="isReadonly || item.json.disabled"
                      @click="handleElecSign(item, index)"
                    >签名</Button
                    >
                    <Button
                      v-else
                      style="float: left"
                      :loading="item.json.elecSignLoading"
                      :disabled="isReadonly || item.json.disabled"
                      @click="handleElecSign(item, index)"
                    >撤销签名</Button
                    >
                  </div>
                  <div class="elec-sign-list">
										<span
                      v-for="(signItem, signIndex) in formData[item.json.code]"
                      class="elec-sign-item"
                      :key="`${signItem.userId}_${signIndex}`"
                    >
											<img
                        :src="`data:image/png;base64,${signItem.img}`"
                        class="sign-img"
                      />
											<span
                        v-if="
													env === 'shenzhenrenmin' && showType === 'history'
												"
                      >{{ getTime(signItem.time) }}</span
                      >
										</span>
                  </div>
                </div>
              </FormItem>
            </div>
          </Col>
        </template>
      </Row>
    </Form>
    <Modal
      v-if="showType === 'approval'"
      title="输入签名密码"
      :value="signatureVisible"
      :mask-closable="false"
      @on-cancel="signatureCancel"
    >
      <Form ref="signature" v-if="signatureVisible">
        <p><a @click="settingSign" class="sign-link">签名设置点这里</a></p>
        <FormItem label="签名密码">
          <Input
            placeholder="请输入签名密码"
            v-model="signaturePassword"
            type="password"
            @on-keyup.enter="handleGetSignatureSubmit"
          />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="signatureCancel">取消</Button>
        <Button
          type="primary"
          :loading="signatureLoading"
          @click="handleGetSignatureSubmit"
        >确定</Button
        >
      </div>
    </Modal>
    <SignatureModal
      :visible="settingSignVisible"
      @onCancel="settingSignVisible = false"
    >
    </SignatureModal>
    <Modal
      v-model="elecSignVisible"
      title="提示"
      :mask-closable="false"
      @on-cancel="handleElecSignCancel"
      :loading="elecSignModalLoading"
    >
      <p style="padding: 20px; font-size: 16px">
        请打开{{ appName }}APP验证证书密码！
      </p>
      <div slot="footer">
        <Button @click="handleElecSignCancel">取消</Button>
        <Button
          type="primary"
          :loading="elecSignModalLoading"
          @click="handleElecSignSubmit"
        >已验证</Button
        >
      </div>
    </Modal>
  </div>
</template>
<script>
import request from "@/utils/request"
import SignatureModal from "@/views/Workbench/components/SignatureModal.vue"
import draggable from "vuedraggable"
import dictionaryApi from "@/api/setting/dictionary"
import signatureApi from "@/api/personalInfo/userSign"
import signApi from "@/api/personalInfo/elecSign"
import interpreter from "@/utils/interpreter"
import { mapState } from "vuex"
import { dataToSha } from "@/utils/util"
import { forEach } from "lodash"
import TableComp from "./TableComp.vue"

const { apiGetOpenAccessData } = dictionaryApi
const { apiGetSignatureByPassword } = signatureApi
const { getSignImg, getSignPassImg, getSZSignImg, getSZSignPassImg } = signApi

export default {
  name: "designTemplate",
  /* isBackFill 布尔值 表示：在新增组件配置里预置了初始值，是否回填。
  需要回填时，传参，否则不传即可，默认不回填。
  一般在新增时，或者表单设计展示时，显示回填，其他时间都不显示，因为已经被修改覆盖了。
  * */
  props: [
    "componentList",
    "submitIdList",
    "showType",
    "updateId",
    "toDoId",
    "projectInfo",
    "fullscreen",
    "entry",
    "isAddOrg",
    "isNoHeight",
    "funcData",
    "isBackFill",
    "isRepeat"
  ],
  components: {
    draggable,
    TableComp,
    SignatureModal
  },
  data() {
    return {
      loading: false,
      list: [],
      signatureVisible: false,
      signaturePassword: "",
      signatureLoading: false,
      selectSignatureItem: null,
      formData: {},
      otherShowObj: {},
      settingSignVisible: false,
      newComponentList: [],
      funcLabel: "",
      funcData2: this.funcData || [],
      elecSignVisible: false,
      elecSignModalLoading: false,
      elecSignId: "",
      elecSignFormItem: null,
      currentIndex: null,
      undraggable: true, // 设置为true禁用拖拽
      selectedItems: [], // 存储选中的组件
      selectAll: false, // 添加全选状态
    }
  },
  computed: {
    isShowTerm() {
      return this.projectInfo && this.projectInfo.terminateDate
    },
    ...mapState({
      contentHeight: state => state.contentHeight - 40
    }),
    ...mapState("user", ["info"]),
    conHeight() {
      if (this.isNoHeight) {
        // 是否不设置高度
        return "unset"
      }
      const heightNum = this.fullscreen
        ? document.body.clientHeight - 145
        : this.contentHeight
      return `${heightNum}px`
    },
    isShow() {
      return this.showType === "design"
    },
    isReadonly() {
      return (
        this.showType === "design" ||
        this.showType === "history" ||
        this.showType === "readonly"
      )
    },
    rules() {
      if (this.showType !== "approval") {
        return {}
      }
      const obj = {}
      this.list.forEach(list => {
        const { json } = list
        if (json[json.code] && json[json.code].length) {
          obj[json.code] = json.customRules.map(rule => rule.rule)
        }
      })
      return obj
    },
    env() {
      return this.$store.state.environment
    },
    appName() {
      if (this.env === "xinhua" || this.env === "shetyy") return "云医签"
      if (this.env === "shenzhenrenmin") return "掌信签"
      return ""
    }
  },
  watch: {
    componentList(componentList) {
      this.$refs.designTemplateForm.resetFields()
      this.newComponentList = this._.defaultsDeep([], this.componentList)
      if (this.entry === "project" && !this.updateId) {
        this.formData = this.getSpaceFormData() // 置空
      } else {
        this.formData = this.getFormData()
      }
      this.formatList(componentList)
    },
    isAddOrg() {
      if (this.isAddOrg) {
        this.formatList(this.newComponentList)
      }
    },
    funcData() {
      this.funcData2 = this.funcData || []
    },
    submitIdList: {
      handler(newVal) {
        if (this.list && this.list.length) {
          if (!newVal || newVal.length === 0) {
            // 如果是空数组，全部选中
            this.list.forEach(item => {
              this.$set(item.json, 'isSelected', true)
            })
            this.selectAll = true
            this.selectedItems = [...this.list] // 更新选中项列表
            this.handleCheckChange()
          } else {
            this.setDefaultSelected(newVal)
          }
        }
      },
      immediate: true
    },
    list: {
      handler() {
        this.checkSelectAll()
      },
      deep: true
    }
  },
  mounted() {
    this.newComponentList = this._.defaultsDeep([], this.componentList)
    this.init()
    
    // 初始化时设置默认选中
    if (this.submitIdList && this.submitIdList.length) {
      this.$nextTick(() => {
        this.setDefaultSelected(this.submitIdList)
      })
    }
  },
  methods: {
    init() {
      // this.$refs.designTemplateForm.resetFields();
      if (this.newComponentList) {
        if (this.entry === "project" && !this.updateId) {
          this.formData = this.getSpaceFormData() // 置空
        } else {
          this.formData = this.getFormData()
        }
        this.formatList(this.newComponentList)
      }
    },
    isShowLabel(item) {
      if (item.json.type === "elec_sign") {
        if (item.json.signType !== 1 || item.json.disabled || this.isReadonly) {
          // 0 手动签名，1 自动签名（隐藏组件）
          return true
        }
        return false
      }
      return !!(item.json.isShowName !== "N")
    },
    resetFormFields() {
      this.$refs.designTemplateForm.resetFields()
    },
    formatList(componentList) {
      this.loading = true
      let arr = this._.defaultsDeep([], componentList)
      // 如果是表單設計页面，需要展示全部的组件。如果不是设计页面，则根据联动展示。
      if (this.showType !== "design") {
        const formNewData = this._.defaultsDeep({}, this.formData) // 对象深拷贝
        for (const key in formNewData) {
          if (Array.isArray(formNewData[key])) {
            formNewData[key] = formNewData[key].join(",")
          } else {
            formNewData[key] = formNewData[key]
          }
        }
        arr = arr.filter(item => {
          let json = {}
          if (item.data) {
            if (typeof item.data === "object") {
              json = item.data
            } else {
              json = JSON.parse(item.data)
            }
            const result = interpreter(json.visExpr, formNewData)
            if (
              !json.visExpr ||
              Object.prototype.toString.call(result) === "[object Object]" ||
              !!(json.visExpr && result)
            ) {
              return true
            }
            this.formData[json.code] = this.initValue(this.formData, json) //  隐藏控件的值 需要根据类型 置空
          }
          return false
        })
      }
      this.$nextTick(() => {
        const allPromise = arr.map(item => this.formatFormItem(item))
        Promise.all(allPromise).then(res => {
          this.list = res
          
          // 在列表加载完成后，检查选中状态
          if (!this.submitIdList || this.submitIdList.length === 0) {
            // 空数组全选
            this.list.forEach(item => {
              this.$set(item.json, 'isSelected', true)
            })
            this.selectAll = true
            this.selectedItems = [...this.list] // 更新选中项列表
            this.handleCheckChange()
          } else {
            // 根据 submitIdList 设置选中状态
            this.setDefaultSelected(this.submitIdList)
          }
        })
        setTimeout(() => {
          this.loading = false
          if (this.entry === "project" && this.updateId) {
            // 修改或者继续补录时，直接提示同名项目是否存在
            this.list.forEach(item => {
              if (item.alias === "project_name") {
                this.onBlur(this.formData[item.json.code], item)
              }
            })
          }
          this.$emit("loaded")
        }, 50)
      })
    },
    // isRequestAgain 1,重新请求接口，刷新下拉列表等。0，不需要重新请求接口。
    formatFormItem(item) {
      let json = {}
      if (item.data) {
        if (typeof item.data === "object") {
          json = item.data
        } else {
          json = JSON.parse(item.data)
        }
        
        // 修改选中状态的设置逻辑
        if (!this.submitIdList || this.submitIdList.length === 0 || this.submitIdList.length === this.componentList.length) {
          json.isSelected = true
        } else {
          json.isSelected = this.submitIdList.includes(item.id)
        }
        
        if (json.alias === "project_acronym") {
          // 项目编号，自动生成 不可修改
          json.disabled = true
        }
        if (json.type === "elec_sign") {
          // CA电子签名，如果当前用户已签名，就显示为 撤销签名
          json.isSelfSign = false
          if (this.formData[json.code] && this.formData[json.code].length) {
            const itemC = this.formData[json.code].find(
              formItem => formItem.userId === this.info.id
            )
            json.isSelfSign = !!itemC // 自己是否提交过电子签名
          }
        }
        if (json.id) {
          return this.getDictionaryData(json, item)
        }
        // 需要请求接口数据
        if (
          (json.isRequest === "1" || json.isRequest === "2") &&
          this.showType !== "design"
        ) {
          return this.getApiList(json, item)
        }
      }

      return new Promise(resolve => {
        resolve(Object.assign(item, { json }))
      })
    },
    // 获取需要请求接口的数据列表。
    async getApiList(json, item) {
      let optionList = []
      const res = await request(json.apiUrl)
      if (res && res.data) {
        optionList = res.data.map(leaf => ({
          value: `${leaf.id}`,
          label: leaf.name || leaf.realName,
          email: leaf.username || ""
        }))
      }
      return Object.assign(item, { json: { ...json, optionList } })
    },
    getFormData() {
      const obj = {}
      const targetList = this.selectedItems.length > 0 ? this.selectedItems : this.newComponentList
      if (targetList && targetList.length) {
        targetList.forEach(list => {
          let json = {}
          if (list.data) {
            if (typeof list.data === "object") {
              json = list.data
            } else {
              json = JSON.parse(list.data)
            }
            const { dataValue, bindType, isRequest, userId } = json
            if (typeof dataValue === "number") {
              obj[json.code] = `${dataValue}`
            } else if (bindType === "date" || bindType === "datetime") {
              obj[json.code] = dataValue ? new Date(dataValue) : ""
            } else if (json.type === "base_textPrompt") {
              // 纯文字提示信息
              obj[json.code] = json.textPrompt
            } else if (json.type === "base_Table") {
              // 表格值
              obj[json.code] = dataValue || [...json.tableObj.rows]
            } else if (json.type === "elec_sign") {
              // CA 电子签名
              obj[json.code] = []
              if (dataValue) {
                let arr = []
                if (typeof dataValue === "object") {
                  arr = dataValue
                } else {
                  arr = JSON.parse(dataValue)
                }
                if (arr.length) {
                  obj[json.code] = arr.map(item => {
                    if (item.todoId) {
                      return item
                    }
                    return {
                      ...item,
                      todoId: this.toDoId
                    }
                  })
                }
              }
            } else if (json.type === "base_Select") {
              obj[json.code] = dataValue
              if (isRequest === "2") {
                obj[json.code] = userId
              }
            } else {
              obj[json.code] = dataValue
            }
            // isBackFill 是否回填，如果是的话，显示回填
            // 表单设计页面 和 新增项目页面 可以回填，修改项目及流程表单及查看都不回填。
            if (
              isRequest !== "2" &&
              this.isBackFill &&
              (this.showType === "design" ||
                (this.entry === "project" && this.showType === "approval"))
            ) {
              if (
                json.type === "base_Checkbox" ||
                json.type === "base_Checkbox_List"
              ) {
                if (json.optionList && json.optionList.length) {
                  const checkedArr = json.optionList.filter(
                    item => !!item.checked
                  )
                  if (checkedArr.length) {
                    obj[json.code] = checkedArr.map(item => item.value)
                  } else {
                    obj[json.code] = []
                  }
                } else {
                  obj[json.code] = []
                }
              } else if (json.type === "base_Radio") {
                if (json.optionList && json.optionList.length) {
                  const checkedItem = json.optionList.find(
                    item => !!item.checked
                  )
                  obj[json.code] = checkedItem ? checkedItem.value : ""
                } else {
                  obj[json.code] = ""
                }
              } else if (json.type === "base_Select") {
                // 注意：下拉框是显示的label值，不是value值。
                // obj[json.code] = dataValue;
                // if (isRequest === '2') {
                //   obj[json.code] = userId;
                // }
                if (json.optionList && json.optionList.length) {
                  const checkedItem = json.optionList.find(
                    item => !!item.checked
                  )
                  obj[json.code] = checkedItem ? checkedItem.label : ""
                } else {
                  obj[json.code] = ""
                }
              } else {
                obj[json.code] = dataValue
              }
            }
          }
        })
      }
      return obj
    },
    getSpaceFormData() {
      // 表单项置空 新增时。
      const obj = {}
      if (this.newComponentList && this.newComponentList.length) {
        this.newComponentList.forEach(list => {
          let json = {}
          if (typeof list.data === "object") {
            json = list.data
          } else {
            json = JSON.parse(list.data)
          }
          // type: base_Textarea, base_Date,"base_Checkbox","base_Radio","base_Select",
          // base_textPrompt： 纯文字提示信息
          obj[json.code] = this.initValue(obj, json)
        })
      }
      return obj
    },
    // 初始化置空
    initValue(obj, json) {
      const newObj = { ...obj }
      if (json.type === "base_Checkbox" || json.type === "base_Checkbox_List") {
        if (json.optionList && json.optionList.length) {
          const checkedArr = json.optionList.filter(item => !!item.checked)
          if (checkedArr.length) {
            newObj[json.code] = checkedArr.map(item => item.value)
          } else {
            newObj[json.code] = []
          }
        } else {
          newObj[json.code] = []
        }
      } else if (json.type === "elec_sign") {
        //   电子签名
        // 初始化有返回的签名图片
        newObj[json.code] = []
        if (json.dataValue) {
          let arr = []
          if (typeof json.dataValue === "object") {
            arr = json.dataValue
          } else {
            arr = JSON.parse(json.dataValue)
          }
          if (arr.length) {
            newObj[json.code] = arr.map(item => {
              if (item.todoId) {
                return item
              }
              return {
                ...item,
                todoId: this.toDoId
              }
            })
          }
        }
      } else if (json.type === "base_Radio") {
        if (json.optionList && json.optionList.length) {
          const checkedItem = json.optionList.find(item => !!item.checked)
          newObj[json.code] = checkedItem ? checkedItem.value : ""
        } else {
          newObj[json.code] = ""
        }
      } else if (json.type === "base_Select") {
        // 注意：下拉框是显示的label值，不是value值。
        if (json.optionList && json.optionList.length) {
          const checkedItem = json.optionList.find(
            item => !!item.checked
          )
          newObj[json.code] = checkedItem ? checkedItem.label : ""
        } else {
          newObj[json.code] = ""
        }
      } else if (json.alias === "project_acronym") {
        // 项目编号，新增时候自动生成
        newObj[json.code] = json.dataValue
      } else if (json.type === "base_textPrompt") {
        // 纯文字提示信息
        newObj[json.code] = json.textPrompt
      } else if (json.type === "base_Table") {
        // 纯文字提示信息
        newObj[json.code] = [...json.tableObj.rows]
      } else {
        newObj[json.code] = ""
      }
      return newObj[json.code]
    },
    async getDictionaryData(json, item) {
      // const res = await apiGetData(json.id);
      const res = await apiGetOpenAccessData({ type: json.id })
      let optionList = []
      if (res) {
        optionList = res.data[0].leaf.map(leaf => ({
          value: `${leaf.id}`,
          label: leaf.name
        }))
      }
      return Object.assign(item, { json: { ...json, optionList } })
    },
    onAddFormItem(item) {
      if (item.json) {
        this.list.push(item)
        if (item.json.type === "base_textPrompt") {
          this.formData[item.json.code] = item.json.textPrompt
        } else if (item.json.type === "base_Table") {
          this.formData[item.json.code] = item.json.tableObj.rows
        } else {
          this.formData[item.json.code] = item.json.dataValue
        }
      } else {
        this.formatFormItem(item).then(res => {
          this.list.push(res)
          if (res.json.type === "base_textPrompt") {
            this.formData[res.json.code] = res.json.textPrompt
          } else if (item.json.type === "base_Table") {
            this.formData[item.json.code] = item.json.tableObj.rows
          } else {
            this.formData[res.json.code] = res.json.dataValue
          }
        })
      }
    },
    itemRadioChange(val, item) {
      if (item.json.isFillRemark === "1") {
        // 回填至审批意见
        if (!val) {
          this.funcData2 = this.funcData2.filter(
            dataItem => dataItem.name !== item.name
          )
        } else {
          const option = item.json.optionList.find(
            optItem => optItem.value === val
          )
          this.funcLabel = option ? option.label : ""
          const isExist = this.funcData2.find(
            dataItem => dataItem.name === item.name
          )
          const obj = {
            isRemake: item.json.isFillRemark,
            funcLabel: this.funcLabel,
            name: item.name
          }
          if (isExist) {
            this.funcData2.forEach(dataItem => {
              if (dataItem.name === item.name) {
                dataItem.funcLabel = this.funcLabel
              }
            })
          } else {
            this.funcData2.push({ ...obj })
          }
        }
        this.$emit("func", this.funcData2)
      }
      // const { optionList } = item.json;
      // const { label, value } = optionList.find(opt => opt.value === val);
      // this.otherShowObj[item.json.code] = (label === '其他');
      this.formatList(this.newComponentList)
    },
    itemCheckboxChange(arr, item) {
      // const { optionList } = item.json;
      // const obj = optionList.find(opt => (arr.includes(opt.value) && opt.label === '其他'));
      // this.otherShowObj[item.json.code] = !!obj;
      this.formatList(this.newComponentList)
    },
    handleChangeSelect(val, item, type) {
      // 下拉选择器，展示和选中的都是label值
      if (item.json.isFillRemark === "1" && type !== "role") {
        // 回填至审批意见
        if (!val) {
          this.funcData2 = this.funcData2.filter(
            dataItem => dataItem.name !== item.name
          )
        } else {
          const isExist = this.funcData2.find(
            dataItem => dataItem.name === item.name
          )
          const obj = {
            isRemake: item.json.isFillRemark,
            funcLabel: val,
            name: item.name
          }
          if (isExist) {
            this.funcData2.forEach(dataItem => {
              if (dataItem.name === item.name) {
                dataItem.funcLabel = val
              }
            })
          } else {
            this.funcData2.push({ ...obj })
          }
        }
        this.$emit("func", this.funcData2)
      }
      // 如果是角色接口，需要区分同名人员，加一个datacode 来存储人员id
      if (type === "role") {
        this.getUserId(val, item)
      }
      this.formatList(this.newComponentList)
    },
    getUserId(val, item) {
      const { optionList, isRequest } = item.json
      if (isRequest === "2") {
        // 角色接口
        const apiItem = val
          ? optionList.find(opt => opt.value === val.value)
          : {}
        this.newComponentList = this.newComponentList.map(list => {
          const newListItem = { ...list }
          if (list.id === item.id && list.data) {
            if (typeof list.data === "object") {
              newListItem.data.userId = apiItem ? apiItem.value : ""
              newListItem.data.userName = apiItem ? apiItem.label : ""
            } else {
              const jsonItem = JSON.parse(list.data)
              jsonItem.userId = apiItem ? apiItem.value : ""
              jsonItem.userName = apiItem ? apiItem.label : ""
              newListItem.data = JSON.stringify(jsonItem)
            }
          }
          return newListItem
        })
      }
    },
    // 电子 签名 手动签名
    handleElecSign(formItem, index) {
      // 目前签名组件仅支持   儿童医院
      if (
        this.env !== "xinhua" &&
        this.env !== "shenzhenrenmin" &&
        this.env !== "shetyy"
      ) {
        return;
      }
      this.currentIndex = index;
      if (formItem.json.isSelfSign) {
        // 如果已签过名，撤销签名。
        this.$Modal.confirm({
          title: "提示",
          content: "确认撤销当前签名吗？",
          onOk: () => {
            this.formData[formItem.json.code] = this.formData[
              formItem.json.code
            ].filter(item => item.userId !== this.info.id);
            formItem.json.isSelfSign = false;
          }
        });
      } else {
        // 当前用户没有签过名，去获取签名图片
        if (formItem.json.elecSignLoading) {
          // 防止多次点击
          return;
        }
        this.$asyncDo(async () => {
          formItem.json.elecSignLoading = true;
          this.elecSignId = "";
          this.elecSignFormItem = null;
          let res = false;
          if (this.env === "xinhua" || this.env === "shetyy") {
            res = await getSignImg({
              inData: JSON.stringify({ todoId: this.toDoId })
            });
          } else if (this.env === "shenzhenrenmin") {
            res = await getSZSignImg({
              inData: JSON.stringify({ todoId: this.toDoId })
            });
          }
          if (res && res.data) {
            this.elecSignId = res.data.id;
            if (this.env !== "shenzhenrenmin" && res.data.isTrust === 1) {
              // 没有设置免密签名，需要去 云医签APP 输入密码
              this.elecSignFormItem = this.list[this.currentIndex];
              this.elecSignVisible = true;
            } else {
              // 没有设置免密签名，会抛出错误。(需要去 云医签APP 输入密码)
              this.formData[formItem.json.code].push({
                userId: this.info.id,
                img: res.data.base64,
                time: res.data.signTime
              });
              this.$Message.success("签名成功");
              this.$nextTick(() => {
                formItem.json.isSelfSign = true;
                formItem.json.elecSignLoading = false;
              });
            }
          } else {
            this.$nextTick(() => {
              formItem.json.isSelfSign = false;
              formItem.json.elecSignLoading = false;
            });
          }
        });
      }
    },
    handleElecSignCancel() {
      this.elecSignModalLoading = false
      this.elecSignFormItem.json.elecSignLoading = false
      this.elecSignVisible = false
    },
    handleElecSignSubmit() {
      this.$asyncDo(async () => {
        this.elecSignModalLoading = true
        let ret = false
        if (this.env === "xinhua" || this.env === "shetyy") {
          ret = await getSignPassImg({
            id: this.elecSignId
          })
        } else if (this.env === "shenzhenrenmin") {
          ret = await getSZSignPassImg({
            id: this.elecSignId
          })
        }
        if (ret) {
          this.formData[this.elecSignFormItem.json.code].push({
            userId: this.info.id,
            img: ret.data.base64,
            time: ret.data.signTime
          })
          this.$Message.success("签名成功")
          this.elecSignFormItem.json.isSelfSign = true
          this.elecSignVisible = false
        }
        this.elecSignModalLoading = false
        this.elecSignFormItem.json.elecSignLoading = false
      })
    },
    getTime(time) {
      if (!time) return ""
      const newTime = time.split(" ")[0]
      const arr = newTime.split("-")
      return `${arr[0]}年${arr[1]}月${arr[2]}日`
    },
    // 个人签名
    handleSignatureShow(formItem) {
      if (this.showType !== "approval") {
        return
      }
      this.selectSignatureItem = formItem
      this.signatureVisible = true
    },
    // 获取签名图片
    handleGetSignatureSubmit() {
      this.$asyncDo(async () => {
        this.signatureLoading = true
        const res = await apiGetSignatureByPassword({
          signPwd: dataToSha(this.signaturePassword)
        })
        if (res) {
          this.signatureVisible = false
          this.formData[this.selectSignatureItem.json.code] = res.data
          // this.selectSignatureItem.json.dataValue = res.data;
        }
        this.signatureLoading = false
      })
    },
    // 取消输入签名密码
    signatureCancel() {
      this.signatureVisible = false
    },
    // 由于页面太长，顶部未填写必填项时，需要自动滚动到相应的位置
    scrollToErrorFormItem() {
      const designTemplateForm = document.getElementById("designTemplateForm")
      const firstErrorFormItem = designTemplateForm.querySelectorAll(
        ".ivu-form-item-error"
      )[0]
      if (firstErrorFormItem) {
        const { top, height } = firstErrorFormItem.getBoundingClientRect()
        const { scrollTop } = designTemplateForm
        designTemplateForm.scrollTop = top - 55 - height + scrollTop
      }
    },
    settingSign() {
      this.settingSignVisible = true
    },
    onBlur(formData, item) {
      if (!formData) return
      this.$emit("onItemBlur", formData, item)
    },
    // 处理复选框变化
    handleCheckChange() {
      this.selectedItems = this.list.filter(item => item.json.isSelected)
      this.checkSelectAll()
      // 发送选择变化事件
      this.$emit('on-select-change', this.selectedItems, this.list)
    },
    // 点击整行时切换选中状态
    toggleSelect(item) {
      item.json.isSelected = !item.json.isSelected
      this.handleCheckChange()
    },
    // 修改验证规则
    validateForm() {
      const selectedFields = this.selectedItems.map(item => item.json.code)
      // 只验证选中字段
      const validRules = {}
      Object.keys(this.rules).forEach(key => {
        if (selectedFields.includes(key)) {
          validRules[key] = this.rules[key]
        }
      })
      return validRules
    },
    // 修改提交方法
    onOk() {
      // 重新获取选中项
      this.selectedItems = this.list.filter(item => item.json.isSelected)
      
      if (!this.selectedItems || this.selectedItems.length === 0) {
        this.$Message.error("请至少选择一个组件，表单不能为空")
        return
      }

      const validRules = this.validateForm()
      this.$refs.designTemplateForm.validate(valid => {
        if (valid) {
          // 只提交选中的内容
          const formData = this.getFormData()
          // ...existing submit logic...
        }
      })
    },
    // 设置默认选中的组件
    setDefaultSelected(idList) {
      if (!this.list || !this.list.length) return
      
      this.list.forEach(item => {
        this.$set(item.json, 'isSelected', idList.includes(item.id))
      })
      
      // 更新选中项列表
      this.selectedItems = this.list.filter(item => item.json.isSelected)
      this.checkSelectAll()
      // 发送选择变化事件
      this.$emit('on-select-change', this.selectedItems, this.list)
    },
    // 添加全选处理方法
    handleSelectAll(checked) {
      this.list.forEach(item => {
        item.json.isSelected = checked
      })
      this.handleCheckChange()
    },
    // 检查是否全选
    checkSelectAll() {
      if (!this.list || this.list.length === 0) {
        this.selectAll = false
        return
      }
      this.selectAll = this.list.every(item => item.json.isSelected)
    }
  }
}
</script>

<style lang="less">
.sign-link {
  font-size: 14px;
}
.design-form-config {
  padding: 10px 16px;
  .ivu-select-input[disabled],
  .ivu-input[disabled],
  .ivu-input-number-input[disabled] {
    color: #333;
  }
  .ivu-input-number-input::placeholder {
    color: #c5c8ce;
  }
  .ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after {
    border-color: #333;
  }
  .ivu-radio-disabled .ivu-radio-inner:after {
    background-color: #716e6e;
  }
  .dele-icon {
    position: absolute;
    font-size: 20px;
    right: 15px;
    top: -10px;
    z-index: 1111;
    width: 24px;
    height: 24px;
    line-height: 26px;
    display: none;
    i {
      line-height: 0;
      vertical-align: middle;
    }
  }
  .ivu-form-item {
    border: 1px solid #fff;
    padding: 2px;
  }
  .list-item-wrap {
    /*> .ivu-row > div {*/
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
  }
  .ivu-form-item-label {
    display: none;
  }
  .form-item-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 0;

    .form-item-label {
      font-weight: bold;
    }
  }
  .form-item-wrap {
    /*border: 1px dashed #ddd;*/
    padding: 0 10px;
  }
  &.design {
    /*height: calc(~'100% - 50px');*/
    /*overflow-y: auto;*/
    /*overflow-x: hidden;*/
    .ivu-form-item {
      cursor: default; // 改为默认鼠标样式
    }
  }

  .terminate-item {
    color: red;
    .ivu-form-item-label {
      color: red;
    }
  }

  .select-all-wrap {
    padding: 0 10px;
    margin-bottom: 10px;
  }
}
/* 电子签名*/
.elec-sign-wrap {
  position: relative;
  .elec-sign-list {
    margin-top: 8px;
    padding: 0 5px 5px;
    min-height: 73px;
    border: 1px solid #eee;
    /*text-align: center;*/
    border-radius: 5px;
    .elec-sign-item {
      padding: 0 20px;
      display: inline-flex;
      align-items: center;
      flex-direction: column;
    }
    .sign-img {
      margin-top: 5px;
      width: 120px;
      height: 60px;
    }
  }
}
</style>

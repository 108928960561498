<template>
	<Modal
		title="查看器械"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="viewData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="器械编号" prop="apparatusNO">
						{{ viewData.apparatusNO || "--" }}
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="器械名称" prop="apparatusName">
						{{ viewData.apparatusName || "--" }}
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="器械数量" prop="totalAmount">
						{{ viewData.totalAmount || "--" }}
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="制造厂商" prop="organizationName">
						{{ viewData.organizationName || "--" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="使用方式" prop="useDescription">
						{{ viewData.useDescription || "--" }}
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="备注" prop="mark">
						{{ viewData.mark || "--" }}
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/apparatus"

const { apiGet } = api
export default {
	name: "ViewDetail",
	props: ["id", "visible"],
	data() {
		return {
			viewData: {},
			loading: false
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.viewData = {}
				if (this.id) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({
							projectId: this.$route.params.projectId,
							id: this.id,
							type: 1
						})
						if (res) {
							this.viewData = {
								...this.viewData,
								...res.data
							}
						}
						this.loading = false
					})
				}
			}
		}
	}
}
</script>

<style scoped></style>
